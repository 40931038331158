import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import projectReducer from './project/project.reducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [],
  stateReconciler: autoMergeLevel2  
}

const projectPersistConfig = {
  key: 'project',
  storage: sessionStorage
}

const rootReducer = combineReducers({
  project: persistReducer(projectPersistConfig,projectReducer)
});


export default persistReducer(persistConfig,rootReducer);