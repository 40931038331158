import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { ProjectActionTypes } from './redux/project/project.types';
// import { hideWarningLayer } from './utils/utils';

import Home from './pages/home/home.page.component';

import './App.css';
import './res.scss';
class App extends React.Component {

  render(){
    return (
      <React.Fragment>
        <div className="progressBarContainer">
          <div className="progressBar"></div>
        </div>  
        <main>
        <Switch>
          <Route exact path='/'>
          {
            //  this.props.isLoggedIn && this.props.loginStart === false ? 
             <Redirect to="/home" />
          }
          </Route>
          <Route exact path='/home' component={Home} />
          <Route>
            <Redirect to="/home" />
          </Route>          
        </Switch>       
      </main>
      </React.Fragment>
    );
  }
}

export default App;
