export const ProjectActionTypes = {
  SET_WARNING_MESSAGE: 'SET_WARNING_MESSAGE',
  SET_LOADING: 'SET_LOADING',
  SET_CURRENT: 'SET_CURRENT',
  SET_MENU: 'SET_MENU',
  SET_DEFAULT: 'SET_DEFAULT',
  SET_BREADCRUMB_TITLE: 'SET_BREADCRUMB_TITLE',
  PAGE_LOAD: 'PAGE_LOAD',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_PARENT: 'SET_PARENT',
  SET_SCROLL: 'SET_SCROLL',
  SET_SEARCH_PAGE: 'SET_SEARCH_PAGE',
  SET_SEARCH_QUERY_PARAMS: 'SET_SEARCH_QUERY_PARAMS',
  SET_LOGINFORM: 'SET_LOGINFORM'
}