import { ProjectActionTypes  } from './project.types';

const INITIAL_STATE = {
  warningMessageShow:false,
  warningMessageType:'fail',
  warningMessageText:'',
  currentPage: '',
  menu: '',
  breadcrumbTitle: '',
  pageload:false,
  showLoading:false,
  language:null,
  parentPage:'',
  scrollPos:0,
  searchPage:1,
  searchQueryParams:{},
  openLoginForm:false

}

const projectReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case ProjectActionTypes.SET_DEFAULT:
      return INITIAL_STATE

    case ProjectActionTypes.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.value
      }
    case ProjectActionTypes.PAGE_LOAD:
      return {
        ...state,
        pageload: action.pageload
      }
  

    case ProjectActionTypes.SET_PAGE_LOADING:
      return {
        ...state,
        isLoading: action.value
      }

    case ProjectActionTypes.SET_WARNING_MESSAGE:
      return {
        ...state,
        warningMessageType: action.payload.warningMessageType,
        warningMessageText: action.payload.warningMessageText        
      }

    case ProjectActionTypes.SET_MENU:
      return {
        ...state,
        menu: action.value
      }

    case ProjectActionTypes.SET_CURRENT:
      return{
        ...state,
        currentPage: action.value
      }

    case ProjectActionTypes.SET_LOADING:
      return{
        ...state,
        showLoading: action.showLoading
      }

    case ProjectActionTypes.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle: action.value
      }
  
    case ProjectActionTypes.SET_LANGUAGE:
      return{
        ...state,
        language: action.language
      }

    case ProjectActionTypes.SET_PARENT:
      return{
        ...state,
        parentPage: action.value
      }

    case ProjectActionTypes.SET_SCROLL:
      return{
        ...state,
        scrollPos: action.value
      }
          
    case ProjectActionTypes.SET_SEARCH_PAGE:
      return{
        ...state,
        searchPage: action.value
      }
                
    case ProjectActionTypes.SET_SEARCH_QUERY_PARAMS:
      return {
        ...state,
        searchQueryParams:{
          page:action.payload.page,
          keyword:action.payload.keyword,
          profession: action.payload.profession
        }
      }
  
    case ProjectActionTypes.SET_LOGINFORM:
      return{
        ...state,
        openLoginForm: action.value
      }
      

    default:
      return state;
  }
}

export default projectReducer;