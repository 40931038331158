import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery,{ useMediaQuery } from 'react-responsive';
import { animateCSS, addClassToElement } from '../../utils/utils';
//import 'animate.css/animate.min.css';
import './home.page.styles.scss';



const Home = () => {

  const n = Math.floor(Date.now() / 1000);
  const isMobile = useMediaQuery({ maxWidth: 960 });

  useEffect(() => {
    if (isMobile){
  
      setTimeout(()=>{
        animateCSS('.itqLogo', 'fadeInLeft').then((message) => {
          // Do something after the animation
          addClassToElement('.itqLogo','setOpacity');
          animateCSS('.itqQuote', 'fadeIn').then((message) => {
            // Do something after the animation
            addClassToElement('.itqQuote','setOpacity');
            addClassToElement('.UlitqProductsIntro','UlitqProductsIntroFade');
            setTimeout(()=>{
              animateCSS('.itqEntrance', 'slideOutUpMobile').then((message) => {
                addClassToElement('.itqEntrance','fixedHeader');
                // addClassToElement('main','page');
                 addClassToElement('.itqContentWrapper','displayFlex');
                

                setTimeout(()=>{
                  animateCSS('.itqContentWrapper', 'fadeIn').then((message) => {
                    addClassToElement('.itqContentWrapper','setOpacity');
                    addClassToElement('.itqFooter','displayFlex');
                    animateCSS('.itqFooter', 'fadeIn').then((message) => {
                      addClassToElement('.itqFooter','setOpacity');
                      const progressBarContainer = document.querySelector(".progressBarContainer");
                      const progressBar = document.querySelector(".progressBar");
                      let totalPageHeight = document.body.scrollHeight - window.innerHeight;
                      let debounceResize;
                      
                      window.addEventListener("scroll", () => {
                        let newProgressHeight = window.pageYOffset / totalPageHeight;
                        progressBar.style.transform = `scale(1,${newProgressHeight})`;
                        progressBar.style.opacity = `${newProgressHeight}`;
                      }, {
                        capture: true,
                        passive: true
                      });
                      
                      window.addEventListener("resize", () => {
                        clearTimeout(debounceResize);
                        debounceResize = setTimeout(() => {
                          totalPageHeight = document.body.scrollHeight - window.innerHeight;
                        }, 250);
                      });
                      
                      progressBarContainer.addEventListener("click", (e) => {
                        let newPageScroll = e.clientY / progressBarContainer.offsetHeight * totalPageHeight;
                        window.scrollTo({
                          top: newPageScroll,
                          behavior: 'smooth'
                        });
                      });                            
                    });
                  });

                },500);
              });
            },4000)
          });           
        });      
      },500);
        
      
    }else{
      setTimeout(()=>{
        animateCSS('.itqLogo', 'fadeInLeft').then((message) => {
          // Do something after the animation
          addClassToElement('.itqLogo','setOpacity');
          animateCSS('.smallSep', 'fadeInDown').then((message) => {
            // Do something after the animation
            addClassToElement('.smallSep','setOpacity');
            animateCSS('.itqQuote', 'fadeIn').then((message) => {
              // Do something after the animation
              addClassToElement('.itqQuote','setOpacity');
              addClassToElement('.UlitqProductsIntro','UlitqProductsIntroFade');
              setTimeout(()=>{
                animateCSS('.itqEntrance', 'slideOutUp').then((message) => {
                  addClassToElement('.itqEntrance','fixedHeader');
                  addClassToElement('main','page');
                  addClassToElement('.itqContentWrapper','displayFlex');
                  
  
                  setTimeout(()=>{
                    animateCSS('.itqContentWrapper', 'fadeIn').then((message) => {
                      addClassToElement('.itqContentWrapper','setOpacity');
                      addClassToElement('.itqFooter','displayFlex');
                      animateCSS('.itqFooter', 'fadeIn').then((message) => {
                        addClassToElement('.itqFooter','setOpacity');
                        const progressBarContainer = document.querySelector(".progressBarContainer");
                        const progressBar = document.querySelector(".progressBar");
                        let totalPageHeight = document.body.scrollHeight - window.innerHeight;
                        let debounceResize;
                        
                        window.addEventListener("scroll", () => {
                          let newProgressHeight = window.pageYOffset / totalPageHeight;
                          progressBar.style.transform = `scale(1,${newProgressHeight})`;
                          progressBar.style.opacity = `${newProgressHeight}`;
                        }, {
                          capture: true,
                          passive: true
                        });
                        
                        window.addEventListener("resize", () => {
                          clearTimeout(debounceResize);
                          debounceResize = setTimeout(() => {
                            totalPageHeight = document.body.scrollHeight - window.innerHeight;
                          }, 250);
                        });
                        
                        progressBarContainer.addEventListener("click", (e) => {
                          let newPageScroll = e.clientY / progressBarContainer.offsetHeight * totalPageHeight;
                          window.scrollTo({
                            top: newPageScroll,
                            behavior: 'smooth'
                          });
                        });                            
                      });
                    });
  
                  },500);
                });
              },4000)
            });              
          });              
        });      
      },500);
  
    }
  

  }, [])

  
    return(
      <React.Fragment>
        <MediaQuery maxWidth={960}>
          <div className="itqEntrance animatedslideOutUpMobile">
            <div className="logoWrapper">
              <a href="/home"><img src={`/assets/images/infotraq-logo-shadow.png?${n}`} alt="Infotraq" className="itqLogo imgResponsive animatedFadeInLeft" /></a>
            </div>
            <div className="itqQuoteWrapper">
              <h1 className="itqQuote animatedFadeIn">what do you want to traq?</h1>
              <ul className="UlitqProductsIntro">
                <li className="li-intro-documents">documents</li>
                <li className="li-intro-flows">flows</li>
                <li className="li-intro-issues">issues</li>
                <li className="li-intro-works">works</li>
                <li className="li-intro-tasks">tasks</li>
                <li className="li-intro-service-busses">service busses</li>
                <li className="li-intro-service-facilities">facilities</li>
              </ul>
            </div>
            
          </div>
          <div className="itqContentWrapper animatedFadeIn">

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>documents</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>any file with classification / grouping / clustering data</h2>
                <p className="itqContentText">office documents / letters / statements</p>
                <p className="itqContentText">images / videos</p>
                <p className="itqContentText">drawings</p>
              </div>
            </div>

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>flows</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>information required for review / consent / approval</h2>
                <p className="itqContentText">purchase orders</p>
                <p className="itqContentText">sales orders</p>
                <p className="itqContentText">annual leaves</p>
              </div>
            </div>
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>issues</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>issues reported by the members of your organization</h2>
                <p className="itqContentText">leakages</p>
                <p className="itqContentText">broken windows /doors</p>
                <p className="itqContentText">scratches on paints</p>
              </div>
            </div>                    

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>works</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>requests from your clients / customers / tenants</h2>
                <p className="itqContentText">additional work requests for existing items</p>
                <p className="itqContentText">requests for fixing existing items in the context of a service agreement</p>
                <p className="itqContentText">new requests to be budgeted for new / additional work</p>
              </div>
            </div>
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>tasks</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>all types of recurring tasks for any type of organization</h2>
                <p className="itqContentText">walkdown route management for shifts with QR code or NFC</p>
                <p className="itqContentText">periodic maintenance / replacement tasks</p>
                <p className="itqContentText">renewal of contracts / licenses / certificates</p>
              </div>
            </div>  
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>service busses</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>enabling communication between employees, students, teachers, instructors, drivers, administration</h2>
                <p className="itqContentText">definition of routes / busses / drivers / passengers</p>
                <p className="itqContentText">management of routes with pools of busses / drivers / passengers / locations</p>
                <p className="itqContentText">automatic route definition based on passengers</p>
              </div>
            </div>      
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>facilities</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>integration with card systems for traqing visitors, guests, buildings, cars, service providers, suppliers</h2>
                <p className="itqContentText">management of entries of employees / guests / visitors / cars</p>
                <p className="itqContentText">management of tasks related with mechanical / electrical / civil / security / administration</p>
                <p className="itqContentText">management of tasks with service providers</p>
              </div>
            </div>                            

          </div>
          <div className="itqFooter">
            <div className="itqFooterWrapper">
              <div className="footerColumnA">
                <img src="/assets/images/infotraq-logo-icon-white.png" alt="infotraq" />
                <p>© 2021 All rights reserved.</p>
              </div>
              <div className="footerColumnB"></div>
              <div className="footerColumnC">
                <p>Infotraq Mobile Client is available at Google Play and Apple Store.</p>
                <p>Please contact <strong>info@infotraq.com</strong> for trial accounts and access details.</p>
                <div className="storeIconsWrapper">
                  <a href="https://play.google.com/store/apps/details?id=com.docplant.itqmclient&hl=en_US&gl=US" target="_blank" rel="noreferrer"><img src="/assets/images/play-store.png" alt="Infotraq Mobile Client - Play Store" /></a>
                  <a href="https://apps.apple.com/us/app/infotraq-mobile-client/id1554876506" target="_blank" rel="noreferrer" ><img src="/assets/images/apple-store.png" alt="Infotraq Mobile Client - Apple Store" /></a>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={961}>
          <div className="itqEntrance animatedslideOutUp">
            <div className="logoWrapper">
              <a href="/home"><img src={`/assets/images/infotraq-logo-shadow.png?${n}`} alt="Infotraq" className="itqLogo imgResponsive animatedFadeInLeft" /></a>
            </div>
            <div className="smallSep animatedFadeInLeft"></div>
            <div className="itqQuoteWrapper">
              <h1 className="itqQuote animatedFadeIn">what do you want to traq?</h1>
              <ul className="UlitqProductsIntro">
                <li className="li-intro-documents">documents</li>
                <li className="li-intro-flows">flows</li>
                <li className="li-intro-issues">issues</li>
                <li className="li-intro-works">works</li>
                <li className="li-intro-tasks">tasks</li>
                <li className="li-intro-service-busses">service busses</li>
                <li className="li-intro-service-facilities">facilities</li>
              </ul>
            </div>
            
          </div>
          <div className="itqContentWrapper animatedFadeIn">

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>documents</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>any file with classification/grouping/clustering data</h2>
                <p className="itqContentText">office documents/letters/statements</p>
                <p className="itqContentText">images/videos</p>
                <p className="itqContentText">drawings</p>
              </div>
            </div>

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>flows</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>information required for review/consent/approval</h2>
                <p className="itqContentText">purchase orders</p>
                <p className="itqContentText">sales orders</p>
                <p className="itqContentText">annual leaves</p>
              </div>
            </div>
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>issues</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>issues reported by the members of your organization</h2>
                <p className="itqContentText">leakages</p>
                <p className="itqContentText">broken windows /doors</p>
                <p className="itqContentText">scratches on paints</p>
              </div>
            </div>                    

            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>works</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>requests from your clients/customers/tenants</h2>
                <p className="itqContentText">additional work requests for existing items</p>
                <p className="itqContentText">requests for fixing existing items in the context of a service agreement</p>
                <p className="itqContentText">new requests to be budgeted for new/additional work</p>
              </div>
            </div>
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>tasks</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>all types of recurring tasks for any type of organization</h2>
                <p className="itqContentText">walkdown route management for shifts with QR code or NFC</p>
                <p className="itqContentText">periodic maintenance/replacement tasks</p>
                <p className="itqContentText">renewal of contracts/licenses/certificates</p>
              </div>
            </div>  
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>service busses</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>enabling communication between employees, students, teachers, instructors, drivers, administration</h2>
                <p className="itqContentText">definition of routes/busses/drivers/passengers</p>
                <p className="itqContentText">management of routes with pools of busses/drivers/passengers/locations</p>
                <p className="itqContentText">automatic route definition based on passengers</p>
              </div>
            </div>      
            <div className="itqContent">
              <div className="itqContentTitle">
                <h2>facilities</h2>
              </div>
              <div className="smallSep"></div>
              <div className="itqContentDetails">
                <h2>integration with card systems for traqing visitors, guests, buildings, cars, service providers, suppliers</h2>
                <p className="itqContentText">management of entries of employees/guests/visitors/cars</p>
                <p className="itqContentText">management of tasks related with mechanical/electrical/civil/security/administration</p>
                <p className="itqContentText">management of tasks with service providers</p>
              </div>
            </div>                            

          </div>
          <div className="itqFooter">
            <div className="itqFooterWrapper">
              <div className="footerColumnA">
                <img src="/assets/images/infotraq-logo-icon-white.png" alt="infotraq" />
                <p>© 2021 All rights reserved.</p>
              </div>
              <div className="footerColumnB"></div>
              <div className="footerColumnC">
                <p>Infotraq Mobile Client is available at Google Play and Apple Store.</p>
                <p>Please contact <strong>info@infotraq.com</strong> for trial accounts and access details.</p>
                <div className="storeIconsWrapper">
                  <a href="https://play.google.com/store/apps/details?id=com.docplant.itqmclient&hl=en_US&gl=US" target="_blank" rel="noreferrer"><img src="/assets/images/play-store.png" alt="Infotraq Mobile Client - Play Store" /></a>
                  <a href="https://apps.apple.com/us/app/infotraq-mobile-client/id1554876506" target="_blank" rel="noreferrer" ><img src="/assets/images/apple-store.png" alt="Infotraq Mobile Client - Apple Store" /></a>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
}

export default Home;